// extracted by mini-css-extract-plugin
export var blue = "FeaturedProject-module--blue--kljuA";
export var buttonLight = "FeaturedProject-module--buttonLight--V4jqB";
export var buttonMain = "FeaturedProject-module--buttonMain--8srHd";
export var buttonPlain = "FeaturedProject-module--buttonPlain--Mjg0p";
export var green = "FeaturedProject-module--green--zePw3";
export var imageSection = "FeaturedProject-module--imageSection--Q9R8k";
export var purple = "FeaturedProject-module--purple--V4Qsl";
export var section = "FeaturedProject-module--section--la4sg";
export var textSection = "FeaturedProject-module--textSection--oDNu8";
export var textSectionWrapper = "FeaturedProject-module--textSectionWrapper--umwbO";